<template>
  <div>
    <div class="message">{{ msg }}</div>
    <h1>{{ happy }}</h1>
    <ul>
      <li v-for="(item, index) in lists" :key="index">{{ item.name }}</li>
    </ul>
    <div>
      <div>计数器：{{ num }}</div>
      <button @click="add">+1</button>
    </div>
    <div>
      单价：{{ goods.price }}, 数量：{{ goods.num }}, 总价：{{ total }}
      <p>
        <button @click="goods.num--">-</button>
        <button @click="goods.num++">+</button>
      </p>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/cfc";

export default {
  name: "index",
  props: {
    msg: String,
  },
  data() {
    return {
      happy: "very happy",
      lists: [
        { name: "张三", age: 18, gender: "男" },
        { name: "李四", age: 19, gender: "女" },
        { name: "王五", age: 23, gender: "女" },
        { name: "赵六", age: 28, gender: "男" },
        { name: "007", age: 38, gender: "男" },
      ],
      goods: {
        price: 1.5,
        num: 6,
      },
      num: 0,
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_BASE_URL);
    getList().then(function(res){
      console.log(res);
    });
  },
  computed: {
    total() {
      if (this.goods.num <= 0) {
        alert("数量不能小于零");
        this.goods.num = 0;
      }
      return this.goods.price * this.goods.num;
    },
  },
  methods: {
    add() {
      this.num++;
    },
  },
};
</script>

<style scoped>
.message {
  background-color: rgb(102, 102, 251);
  color: white;
}
</style>